import React, { useRef } from 'react'
import './Join.css'
import emailjs from 'emailjs-com';

const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ipp7npe', 'template_pbsuvy9', form.current, 'QDDlp3J3MKe6w1twz')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    
    return (
        <div className='Join' id='join-us'>
            <div className="left-j">
                <hr />
                <div>
                    <span className='stroke-text'>Ready to</span>
                    <span> level up</span>
                </div>
                <div>
                    <span>your body</span>
                    <span className='stroke-text'> with us?</span>
                </div>

            </div>
            <div className="right-j">
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name="user_email" placeholder='Enter Your Email Address' />
                    <button className='btn btn-j'>Join Now</button>

                </form>

            </div>


        </div>
    )
}

export default Join