import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import Hero_image from '../assets/hero_image.png'
import Hero_image_back from '../assets/hero_image_back.png'
import Heart from '../assets/heart.png'
import Calories from '../assets/calories.png'
import { motion } from 'framer-motion'
import NumberCounter from 'number-counter'


const Hero = () => {

    const transition = { type: 'spring', duration: 3 }
    const mobile = window.innerWidth <= 768 ? true : false;
    return (
        <div className="hero" id='home'>

            <div className="blur hero-blur-1"></div>
            <div className="left-h">
                <Header />
                {/* THe BEst Ad */}
                <div className="the-best-ad">
                    <motion.div
                        initial={{ left: mobile ? '165px' : '238px' }}
                        whileInView={{ left: '8px' }}
                        transition={{ ...transition, type: 'tween' }}
                    >


                    </motion.div>
                    <span>The Best Fitness GYM in the Town</span>
                </div>

                {/* Hero Heading */}
                <div className="hero-text">
                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your </span>
                    </div>
                    <div>
                        <span>Ideal Body</span>
                    </div>
                    <div>
                        <span>
                            In here we will help you to shape and build your ideal body and live up your life to fullest

                        </span>
                    </div>
                </div>

                {/* Figures */}
                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter end={27} start={0} delay='4' preFix="+" />
                        </span>
                        <span>Expert coachs</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={432} start={387} delay='4' preFix="+" />
                        </span>
                        <span>Members Joined</span>
                    </div>
                    <div>
                        <span>
                            <NumberCounter end={45} start={0} delay='4' preFix="+" />
                        </span>
                        <span>Fitness Programs</span>
                    </div>
                </div>

                {/* Hero Button */}
                <div className="hero-buttons">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>
            </div>
            <div className="right-h">
                <button className='btn'>Join Now</button>


                <motion.div
                    initial={{ right: '-1rem' }}
                    whileInView={{ right: '4rem' }}
                    transition={transition}

                    className="heart-rate">
                    <img src={Heart} alt="Poor Connection" />
                    <span>Heart Rate</span>
                    <span>108 bpm</span>
                </motion.div>

                {/* hero image */}

                <img src={Hero_image} alt="Poor Connection" className='hero-image' />
                <motion.img
                    initial={{ right: '11rem' }}
                    whileInView={{ right: '23rem' }}
                    transition={transition}

                    src={Hero_image_back} alt="Poor Connection" className='hero-image-back' />

                {/* calories */}
                <motion.div className="calories"
                    initial={{ right: '49rem' }}
                    whileInView={{ right: '38rem' }}
                    transition={transition}

                >
                    <img src={Calories} alt="Poor Connection" />
                    <div>
                        <span>Calories Burned </span>
                        <span>225 kcal</span>
                    </div>
                </motion.div>



            </div>

        </div>
    )
}

export default Hero