import React, { useState } from 'react'
import './Header.css';
import Logo from '../../component/assets/logo.png'
import Bars from '../../component/assets/bars.png'
import {Link} from 'react-scroll'

const Header = () => {

  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false)
  return (
    <div className="header">
      <img className="logo" src={Logo} alt="" />
      {(menuOpened === false && mobile === true) ? (
        <div
          style={{
             backgroundColor: 'var(--appColor)',
              padding: '0.9rem', 
              borderRadius: '5px',
              cursor:'pointer' 
            }}
            onClick={()=>setMenuOpened(true)}

        >
          <img src={Bars} alt="Poor Connection" style={{ width: '1.5rem', height: '1.5rem', }} /></div>
      ) :
        <ul className='header-menu'>
          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='home'
          span={true}
          smooth = {true}
          >Home</Link></li>


          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='program'
          span={true}
          smooth = {true}
          >Programs</Link></li>


          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='Reasons'
          span={true}
          smooth = {true}
          >Why Us</Link></li>


          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='Plans'
          span={true}
          smooth = {true}
          >Plans</Link></li>


          <li><Link
          onClick={()=>setMenuOpened(false)}
          to='testimonials'
          span={true}
          smooth = {true}
          >Testimonials</Link></li>
        </ul>
      }


    </div>
  )
}

export default Header