import React from 'react'
import './Footer.css'
import Github from "../assets/github.png"
import Instagaram from "../assets/instagram.png"
import Linkedin from "../assets/linkedin.png"
import Logo from "../assets/logo.png"
const Footer = () => {
    return (
        <div className='Footer-container'>
            <hr />
            <div className="footer">
                <div className="social-links">

                    <img src={Github} alt="Poor Connection" />
                    <img src={Instagaram} alt="Poor Connection" />
                    <img src={Linkedin} alt="Poor Connection" />
                </div>
                <div className="logo-f">
                    <img src={Logo} alt="Poor Connection" />
                </div>
            </div>

            <div className="blur footer-blur-1"></div>
            <div className="blur footer-blur-2"></div>

        </div>
    )
}

export default Footer