import React from 'react'
import './Reasons.css'
import image1 from '../assets/image1.png'
import image2 from '../assets/image2.png'
import image3 from '../assets/image3.png'
import image4 from '../assets/image4.png'
import nb from '../assets/nb.png'
import nike from '../assets/nike.png'
import adidas from '../assets/adidas.png'
import tick from '../assets/tick.png'

const Reasons = () => {
    return (

        <div className="reasons" id='Reasons'>
            <div className="blur r-blur-1"></div>
            {/* <div className="blur r-blur-2"></div> */}
            <div className="left-r">
                <img src={image1} alt="Poor Connection" />
                <img src={image2} alt="Poor Connection" />
                <img src={image3} alt="Poor Connection" />
                <img src={image4} alt="Poor Connection" />
            </div>
            <div className="right-r">
                <span>Some Reasons</span>

                <div>
                    <span className='stroke-text'>why </span>
                    <span>choose us?</span>
                </div>

                <div className='details-r'>
                    <div>
                        <img src={tick} alt="Poor Connection"></img>
                        <span>over 27+ expert coachs</span>
                    </div>
                    <div>
                        <img src={tick} alt="Poor Connection"></img>
                        <span>train smarter and faster than before</span>
                    </div>
                    <div>
                        <img src={tick} alt="Poor Connection"></img>
                        <span>1 free program for new member</span>
                    </div>
                    <div>
                        <img src={tick} alt="Poor Connection"></img>
                        <span>reliable partners</span>
                    </div>
                </div>
                <span
                    style={{
                        color: "var(--gray)",
                        fontWeight: "normal"
                    }}
                >
                    OUR PARTNER
                </span>

                <div className="partners">
                    <img src={nb} alt="Poor Connection" />
                    <img src={adidas} alt="Poor Connection" />
                    <img src={nike} alt="Poor Connection" />
                </div>
            </div>

        </div>
    )
}

export default Reasons